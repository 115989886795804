<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader> <strong>Edit Product Category</strong> </CCardHeader>
          <CCardBody v-if="isLoading" class="text-center">
            <CSpinner color="primary" size="lg" />
          </CCardBody>
          <CForm @submit.prevent="submit" autocomplete="off" v-else>
            <CCardBody>
              <CRow>
                <CCol sm="12">
                  <div class="mb-3 text-center">
                    <input
                      class="d-none"
                      type="file"
                      @change="selectFile"
                      accept="image/*"
                      ref="image"
                    />
                    <img
                      @click="$refs.image.click()"
                      height="150px"
                      :src="imageUrl"
                    />
                  </div>
                </CCol>
              </CRow>
              <CRow>
                <CCol sm="4">
                  <CInput
                    type="text"
                    autocomplete="off"
                    label="Brand Name"
                    v-model="product_category_to_edit.name"
                    placeholder="Enter brand name..."
                    required
                    @keyup="check_name"
                    :addInputClasses="name_invalid ? 'is-invalid' : null"
                    :isValid="product_category_to_edit.name ? true : null"
                  />
                </CCol>
                <CCol sm="4">
                  <div class="input-group" style="margin-top: 30px">
                    <div class="input-group-prepend">
                      <label class="input-group-text" for="inputGroupSelect01"
                        >Product Group</label
                      >
                    </div>
                    <select
                      required
                      class="custom-select"
                      id="inputGroupSelect01"
                      v-model="product_category_to_edit.product_group.id"
                      @change="
                        computedBrand(product_category_to_edit.product_group.id)
                      "
                      :addInputClasses="
                        product_group_id_invalid ? 'is-invalid' : null
                      "
                      :isValid="
                        product_category_to_edit.product_group.id ? true : null
                      "
                    >
                      <template v-for="product_group in product_groups">
                        <option
                          :key="product_group.id"
                          :value="product_group.id"
                        >
                          {{ product_group.name }}
                        </option>
                      </template>
                    </select>
                  </div>
                </CCol>
                <CCol sm="4" v-if="product_category_to_edit.product_group.id">
                  <div class="input-group" style="margin-top: 30px">
                    <div class="input-group-prepend">
                      <label class="input-group-text" for="inputGroupSelect01"
                        >Brand</label
                      >
                    </div>               
                    <select
                      required
                      class="custom-select"
                      id="inputGroupSelect01"
                      v-model="product_category_to_edit.brand.id"
                      :addInputClasses="brand_id_invalid ? 'is-invalid' : null"
                      :isValid="product_category_to_edit.brand.id ? true : null"
                    >
                      <template v-for="brand in computedBrands">
                        <option :key="brand.id" :value="brand.id">
                          {{ brand.name }}
                        </option>
                      </template>
                    </select>
                  </div>
                </CCol>
                <CCol sm="4" v-else>
                  <div class="input-group" style="margin-top: 30px">
                    <div class="input-group-prepend">
                      <label class="input-group-text" for="inputGroupSelect01"
                        >Brand</label
                      >
                    </div>
               
                    <select
                      required
                      disabled
                      class="custom-select"
                      id="inputGroupSelect01"                    
                      :addInputClasses="brand_id_invalid ? 'is-invalid' : null"
                      :isValid="product_category_to_edit.brand.id ? true : null"
                    >
                    
                    </select>
                  </div>
                </CCol>
              </CRow>
              <CRow>
                <CCol sm="12">
                  <CTextarea
                    label="Description"
                    v-model="product_category_to_edit.description"
                    placeholder="Enter Description..."
                  />
                </CCol>
              </CRow>
            </CCardBody>
            <CCardFooter>
              <CButtonToolbar class="justify-content-between">
                <CButton
                  size="sm"
                  color="success"
                  type="submit"
                  v-if="!isSaving"
                  ><CIcon name="cil-check-circle" /> Update</CButton
                >
                <CButton color="secondary" class="px-4" disabled v-else>
                  <CSpinner grow size="sm" /> Saving..
                </CButton>
              </CButtonToolbar>
            </CCardFooter>
          </CForm>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "ProductCategoryEdit",
  data() {
    return {
      product_category_to_edit: [],
      name_invalid: false,
      image: null,
      changedImage: null,
      product_group_id_invalid: false,
      brand_id_invalid: false,
    };
  },
  computed: {
    ...mapState({
      product_groups: (state) => state.product_groups.product_groups,
      computedBrands: (state) => state.brands.computedBrands,
      editProductCategory: (state) =>
        state.product_categories.editProductCategory,
      isSaving: (state) => state.product_categories.isSaving,
      isLoading: (state) => state.product_categories.isLoading,
    }),
    imageUrl() {
      return this.changedImage
        ? this.changedImage
        : this.product_category_to_edit.image ||
            "http://laptopplaza.duckpeon.com/uploads/no-photo.png";
    },
  },
  created() {
 
    if (!this.$store.state.access_token) {
      this.$router.push({ name: "Login" });
    }
    if (!this.editProductCategory) {
      this.$router.push({ name: "Product Categories" });
    }
   this.$store.commit("product_categories/loading", true);
    this.$store.dispatch("product_groups/getAllProductGroups");
    this.$store.dispatch("brands/getAllBrands");
    this.beforeEditingCache = JSON.stringify(this.editProductCategory);
    this.product_category_to_edit = this.editProductCategory
      ? JSON.parse(this.beforeEditingCache)
      : [];
  },

  methods: {
    computedBrand(product_group_id) {
      this.$store.dispatch("brands/computedBrands", product_group_id);
    },
    selectFile(event) {
      this.image = event.target.files[0];
      this.changedImage = URL.createObjectURL(this.image);
    },
    check_name() {
      if (!this.product_category_to_edit.name) {
        this.name_invalid = true;
      } else {
        this.name_invalid = false;
      }
    },
    check_product_group_id() {
      if (!this.product_category_to_edit.product_group.id) {
        this.product_group_id_invalid = true;
      } else {
        this.product_group_id_invalid = false;
      }
    },
    check_brand_id() {
      if (!this.product_category_to_edit.brand.id) {
        this.brand_id_invalid = true;
      } else {
        this.brand_id_invalid = false;
      }
    },
    checkForm() {
      if (!this.product_category_to_edit.name) {
        this.check_name();
      } else {
        return true;
      }
      if (!this.product_category_to_edit.product_group.id) {
        this.check_product_group_id();
      } else {
        return true;
      }
      if (!this.product_category_to_edit.brand.id) {
        this.check_brand_id();
      } else {
        return true;
      }
    },
    submit() {
      if (this.checkForm()) {
        const data = new FormData();
        data.append("id", this.product_category_to_edit.id);
        if (this.image) {
          data.append("img", this.image);
        }
        data.append("name", this.product_category_to_edit.name);
        data.append("description", this.product_category_to_edit.description);
        data.append(
          "product_group_id",
          this.product_category_to_edit.product_group.id
        );
        data.append("brand_id", this.product_category_to_edit.brand.id);
        this.$store.dispatch("product_categories/updateProductCategory", data);
      } else {
        this.checkForm();
      }
    },
  },
};
</script>
 